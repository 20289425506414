<template>
    <div class="page">
        <div :class="['header', { 'header-active': isVisable }]">
            <img class="logo" :src="isLogo ? blogo : wlogo" />
            <div class="right">
                <div class="button" @click="sy">首页</div>
                <div class="button" @click="ywbj">业务布局</div>
                <div class="button" @click="gywm">关于我们</div>
                <div class="button" @click="xwzx">新闻资讯</div>
                <div class="button" @click="qygg">企业公告</div>
                <div class="button" @click="lxwm">联系我们</div>
            </div>
        </div>
        <img class="img" id="sy" src="../assets/logo.png" />
        <img class="img2" src="../assets/logo2.png" />
        <div class="body">
            <!-- 业务布局 -->
            <div class="business" id="ywbj">
                <img class="business-img" src="../assets/business.png" />
                <div class="business-main">
                    <img class="left" src="../assets/business-left.png" />
                    <div class="right">
                        <div class="title">现代化海洋牧场</div>
                        <div class="text">
                            我司已取得位于阳江市阳东区大沟镇三丫村寿长河口西侧海域及西南面对开海域使用权，包括阳江市隆丰养殖专业合作社、阳江市顺水养殖专业合作社、任海艳等海域，面积约220公顷。
                        </div>
                    </div>
                </div>
                <div class="main">
                    说起阳江的蚝，大家一定不约而同地会想到被誉为“海底牛奶”的阳西“程村蚝”，然而随着阳东区对当地特色农产品的大力宣传和推广，同属于阳江的另一种蚝——‘寿长蚝’也开始走进人们的视野。“寿长蚝”是生长在阳东区特定的养殖海域中的品种。这里的水质清新、营养丰富，为“寿长蚝”的生长提供了良好的环境条件。与一般牡蛎相比，“寿长蚝”的肉质更加肥美细嫩，味道鲜美，且富含蛋白质、脂肪、维生素和矿物质等营养成分，具有极高的营养价值。<br />我司位于寿长河口外部分海域已采用开放式养殖方式在2022年开始养殖生蚝，年产量30万串，约3750吨。为项目养殖积累了丰富的经验及技术，奠定了坚实的基础。<br />除了生蚝，我们的海产养殖品种还包括石斑鱼、卵形鲳鲹、海鲈鱼、海藻
                    如：龙须菜、麒麟菜、马尾藻等。
                </div>
            </div>
            <!-- 生蚝养殖 -->
            <div class="oyster-farming">
                <img alt="" :src="preview[previewIndex].src" />

                <div class="farming__button__group">
                    <div class="group__item" :class="{ active: previewIndex === 0 }" @click="previewIndex = 0"></div>
                    <div class="group__item" :class="{ active: previewIndex === 1 }" @click="previewIndex = 1"></div>
                </div>
            </div>
            <div class="fusion">
                <img class="fusion-left" src="../assets/fusion-left.png" />
                <div class="fusion-right">
                    <div class="title">文旅融合区</div>
                    <div class="main">
                        文旅融合区由三个主体构成，通过3条200m钢制栈道和3条270m玻璃栈道链接，全透明亚克力板栈道开发为无边际摄影平台，为游客提供极佳视野和观光体验。
                    </div>
                </div>
            </div>
            <div class="detail">
                <img class="detail-img" src="../assets/fusion-ball.png" />
                <div class="detail-main">
                    （1）总体酒店-4层(11300平方米)：游客住宿区、宴会厅、办公室、会议室、捕鱼自助餐厅、产学研基地、电影院、海洋科普、海产品购物中心以及智慧渔业中心。<br />
                    （2）休闲娱乐区(5000平方米)：包含无人机摄影、飞艇、水上摩托、香蕉船、冲浪等娱乐休闲设施；
                    <br />（3）生产生活资料储备区(5000平方米)：包括波浪能发电系统、太阳能发电系统、仓储储蓄区、海水淡化装置和污水处理设备；
                    <br />（4）潜水训练基地+海上娱乐区(35000平方米) <br />（5）（6）（7） 养殖区+垂钓(48000平方米) <br />（8）船运接驳点 <br />（9）上下岸扶梯
                    200m：普通栈道 270m：玻璃栈道
                </div>
            </div>
            <div class="swiper-list">
                <img class="head" src="../assets/imgList1-head.png" />

                <div class="swiper mySwiper swiper-initialized swiper-horizontal swiper-backface-hidden">
                    <div class="swiper-wrapper" id="swiper-wrapper-42e2dabb10f4f101fa" aria-live="polite">
                        <div class="swiper-slide" v-for="(item, index) in imgList1" :key="index">
                            <img :src="item.src" style="height: 340px" alt="图片丢失了" />
                        </div>
                    </div>
                    <img class="swiper-button-next" src="@/assets/swper-btn.png" />
                </div>
            </div>
            <div class="swiper-list">
                <img class="head" src="../assets/imgList2-head.png" />

                <div class="swiper mySwiper swiper-initialized swiper-horizontal swiper-backface-hidden">
                    <div class="swiper-wrapper" id="swiper-wrapper-42e2dabb10f4f101fa" aria-live="polite">
                        <div class="swiper-slide" v-for="(item, index) in imgList2" :key="index">
                            <img :src="item.src" style="height: 340px" alt="图片丢失了" />
                        </div>
                    </div>
                    <img class="swiper-button-next" src="@/assets/swper-btn.png" />
                </div>
            </div>

            <div class="swiper-list">
                <img class="head" src="../assets/imgList3-head.png" />

                <div class="swiper mySwiper swiper-initialized swiper-horizontal swiper-backface-hidden">
                    <div class="swiper-wrapper" id="swiper-wrapper-42e2dabb10f4f101fa" aria-live="polite">
                        <div class="swiper-slide" v-for="(item, index) in imgList3" :key="index">
                            <img :src="item.src" style="height: 340px" alt="图片丢失了" />
                        </div>
                    </div>
                    <img class="swiper-button-next" src="@/assets/swper-btn.png" />
                </div>
            </div>
            <div class="industrial">
                <img class="industrial-left" src="../assets/industrial-left.png" />
                <div class="industrial-right">
                    <div class="title">岸基配套产业园</div>
                    <div class="main">
                        产业园建设分区包括蚝壳深加工厂、综合办公大楼、员工宿舍、环保设施等，各功能分区布局，致力打造海上养殖及岸基产业园一体化的渔业产业链。
                    </div>
                </div>
            </div>
            <div class="product">
                <img class="product-left" src="../assets/product-left.png" />
                <div class="product-right">
                    <div class="title">产品中心</div>
                    <div class="main">除了生蚝，我们的海产养殖品种还包括石斑鱼、卵形鲳鲹、海鲈鱼、海藻 如：龙须菜、麒麟菜、马尾藻等。</div>
                </div>
            </div>
            <div class="photo">
                <img class="img" src="../assets/industrial1.png" />
                <img class="img" src="../assets/industrial2.png" />
                <img class="img" src="../assets/industrial3.png" />
                <img class="img" src="../assets/industrial4.png" />
                <img class="img" src="../assets/industrial5.png" />
                <img class="img" src="../assets/industrial6.png" />
                <img class="img" src="../assets/industrial7.png" />
            </div>

            <!-- 关于我们 -->
            <div class="about" id="gywm">
                <img class="about-img" src="../assets/about.png" />
                <div class="about-top">
                    <div class="left">
                        <div class="box1">
                            <div class="num">1.2</div>
                            <div class="unit">注册资本/亿元</div>
                        </div>
                        <div class="box2">
                            <div class="num"><span class="yue">约</span>220</div>
                            <div class="unit">海域面积/公顷</div>
                        </div>
                    </div>
                    <div class="right">
                        <img class="right-img" src="../assets/about-right.png" />
                    </div>
                </div>
                <div class="about-main">
                    <div class="box1">
                        阳江海心科技有限公司隶属广州睿得利投资集团旗下，成立于2023年4月，注册资本 1.2 亿，拥有海域使用面积约 220 公顷。
                        <br />陆上基地占地 100000 平方米，建筑面积 20000 平方米，水产养殖场项目设施农业用地项目用地面积 17257 平方米。
                        <br />主要从事农业经济项目开发和水产养殖，通过ISO9001认证，是集海产品良种繁育、海产品加工、现代化海洋牧场建设、高新科技研发等为一体的企业。
                    </div>
                    <div class="box2">
                        海心科技依据自身的商业资源优势和商业理念，在阳江市阳东区大沟镇附近海域及陆地，建设集生产（养殖、种植）新技术研究、产业配套、示范推广于一体的蓝色农业项目，内容包括现代化海洋牧场、岸基配套产业园等。
                        <br />该项目的实施既能够践行生态文明建设的要求，又能够推动当地经济高效发展，满足广东沿海乡村振兴的需要。
                        <br />公司拥有国内顶尖的海洋生态、水产养殖、海洋生物功能材料等专家以及经验丰富的技术团队作为支撑，于2021年委托中国科学院南海海洋研究所进行了“阳江市蓝色农业规划——新型现代化海洋牧场示范基地方案”的课题研究。
                        <br />并于2024年5月与中国水产科学研究院南海水产研究所签订《合作协议书》，以共同推进现代水产业的科技创新和产业升级。
                    </div>
                </div>
                <img class="img" src="../assets/information.png" />
                <!-- <img class="img" src="../assets/mission.png" /> -->
            </div>
            <figure class="figure wow fadeInUp">
                <div class="figure-column">
                    <div class="figure-column-item">
                        <img class="img" src="../assets/figure1.png" />
                    </div>
                    <div class="figure-column-active">
                        <img class="img" src="../assets/figure11.png" />
                    </div>
                </div>
                <div class="figure-column">
                    <div class="figure-column-item">
                        <img class="img" src="../assets/figure2.png" />
                    </div>
                    <div class="figure-column-active">
                        <img class="img" src="../assets/figure22.png" />
                    </div>
                </div>
                <div class="figure-column">
                    <div class="figure-column-item">
                        <img class="img" src="../assets/figure3.png" />
                    </div>
                    <div class="figure-column-active">
                        <img class="img" src="../assets/figure33.png" />
                    </div>
                </div>
                <div class="figure-column">
                    <div class="figure-column-item">
                        <img class="img" src="../assets/figure4.png" />
                    </div>
                    <div class="figure-column-active">
                        <img class="img" src="../assets/figure44.png" />
                    </div>
                </div>
            </figure>
            <img class="bgImg" src="../assets/about-bg.png" />

            <!-- 新闻资讯 -->
            <div class="news" id="xwzx">
                <img class="img" src="../assets/news.png" />
                <div class="news__container">
                    <div class="container__item">
                        <img alt="" src="@/assets/news1.png" />
                        <div class="item__main">
                            <div class="title">阳江海心科技有限公司成为Blackjack Racing中国车手黎智聪的赞助商</div>
                            <div class="button">
                                <div class="button-day">2024-05-11</div>
                                <div class="button-detail" @click="newsDialog1()">查看详情</div>
                            </div>
                        </div>
                    </div>
                    <div class="container__item">
                        <img alt="" src="@/assets/news2.png" />
                        <div class="item__main">
                            <div class="title">海心科技与南建携手，共促数字海洋牧场发展</div>
                            <div class="button">
                                <div class="button-day">2024-05-28</div>
                                <div class="button-detail" @click="newsDialog2()">查看详情</div>
                            </div>
                        </div>
                    </div>
                </div>
                <el-dialog title="阳江海心科技有限公司成为Blackjack Racing中国车手黎智聪的赞助商" :visible.sync="centerNewsDialog1" width="70%" center align-center>
                    <el-scrollbar>
                        <div class="news__dialog">
                            <span class="newsMain"
                                ><p>
                                    2024年兰博基尼super
                                    Trofeo亚洲挑战赛赛季揭幕战在雪邦国际赛车场举行。二十一赛车（BlackJackRacing）中国车手黎智聪出战本次比赛，并以优异表现夺得第二场排位赛的全场杆位并在下午的第一回合正赛获得全场第四名。
                                </p>
                                <p>阳江海心科技有限公司作为一家科技兴海，绿色发展的海洋科技公司。我们荣幸的成为二十一赛车（BlackJack Racing）中国车手黎智聪的赞助商。</p>
                                <img class="newsImg" src="../assets/news1.png" />
                                <p>
                                    黎智聪是一位多才多艺的赛车手，他不仅在中国卡丁车运动领域取得了显著的成就，同时也作为渔民新村餐饮品牌的董事长，在商业管理方面也展现了卓越的才能。
                                </p>
                                <p>
                                    作为中国卡丁车运动员，黎智聪在 2006 年全国卡丁车锦标赛沙井站的国家少年 NCJ- A 组中荣获冠军，并在深圳沙井年度会赛中获得 YAMADA
                                    组年度总冠军。这些成就不仅证明了他在卡丁车运动中的才华和潜力，也为他赢得了广泛的认可和赞誉。黎智聪的成功故事激励着无数年轻运动员，成为中国卡丁车运动的标杆和榜样。
                                </p>
                                <p>
                                    在商业领域，黎智聪作为渔民新村餐饮品牌的董事长，通过引入新的商业模式和管理策略，有效地提升了渔民新村的经济效益。他的努力和创新不仅为渔民新村餐饮品牌赢得了良好的市场声誉，也为顾客提供了高质量的用餐体验。
                                </p>
                                <p>
                                    黎智聪的这些成就和努力，不仅在体育和商业领域留下了深刻的印记，也为他赢得了“渔民新村董事长”和“中国卡丁车运动员”的双重身份。他的成功故事激励着更多的人投身于各自的领域，共同为社会的进步和发展做出贡献。
                                </p>
                                <img class="newsImg" src="../assets/news11.png" />
                                <p>
                                    如今，阳江海心科技有限公司与黎智聪合作的举措，体现了一种互利共赢的合作模式。这种合作不仅有助于提升阳江海心科技有限公司的品牌形象，通过黎智聪在赛车领域的专业表现和影响力，增强品牌的市场认知度。
                                </p>
                                <p>
                                    此外，我们秉承“科技，创新，绿色”的价值观与车手黎智聪在竞技赛场上，结合硬核技术，不断突破个人极限，追求刷新成绩的理念相契合。我们期待通过这样的赞助并借助F1此类顶级赛事，向新生代传递可持续发展，对未来热血沸腾充满期待的生活态度。
                                </p>
                                <p>
                                    总的来说，阳江海心科技有限公司与黎智聪的合作是一次成功的合作，通过这种合作，双方不仅实现了自身的发展目标，也为赛车运动的推广和发展做出了贡献。面向未来，双方将继续秉承共同的理念，迎接更多的挑战和机遇。
                                </p>
                                <img class="newsImg" src="../assets/news111.png" />
                            </span>
                            <br /><br />
                            <div class="href">
                                详情请浏览官方推文：<a href="https://mp.weixin.qq.com/s/0FwqBHh0Ls9mBbWYd5MmuQ">https://mp.weixin.qq.com/s/0FwqBHh0Ls9mBbWYd5MmuQ</a>
                            </div>
                            <span slot="footer" class="dialog-footer">
                                <el-button @click="centerNewsDialog1 = false">取 消</el-button>
                                <el-button type="primary" @click="centerNewsDialog1 = false">确 定</el-button>
                            </span>
                        </div>
                    </el-scrollbar>
                </el-dialog>
                <el-dialog title="海心科技与南建携手，共促数字海洋牧场发展" :visible.sync="centerNewsDialog2" width="70%" center align-center>
                    <el-scrollbar>
                        <div class="news__dialog">
                            <span class="newsMain"
                                ><p>
                                    5月28日，阳江海心科技有限公司（以下简称“海心科技”）调研团队访问了广东南方建设有限公司（以下简称“南建”），就数字海洋牧场领域进行了深入的研讨和交流。
                                </p>
                                <img class="newsImg" src="../assets/news2.png" />
                                <p>
                                    座谈会上，海心科技的代表们详尽阐述了公司的发展历史和数字海洋牧场的未来规划。与此同时，南建的代表们也分享了公司在信息化建设和维护服务方面的丰富经验和最新业务进展。作为国内领先的信息化及网络建设服务提供商，南建在泛在智能网络服务和云产业一体化服务方面取得了显著成就。
                                </p>
                                <img class="newsImg" src="../assets/news22.png" />
                                <p>
                                    双方在会上就未来数字海洋牧场信息化领域的合作进行了深入的探讨，并达成了初步共识。海心科技与南建计划携手合作，共同推动数字海洋牧场的创新与发展，实现资源共享和优势互补，以促进双方业务的共同增长。
                                </p>
                                <img class="newsImg" src="../assets/news222.png" />
                                <p>
                                    此次研讨交流标志着两家公司将在海洋经济和信息化、数字化领域展开更加紧密的合作，共同探索和开拓新的业务机会，为行业进步和社会的发展贡献力量。
                                </p>
                            </span>
                            <span slot="footer" class="dialog-footer">
                                <el-button @click="centerNewsDialog2 = false">取 消</el-button>
                                <el-button type="primary" @click="centerNewsDialog2 = false">确 定</el-button>
                            </span>
                        </div>
                    </el-scrollbar>
                </el-dialog>
            </div>

            <!-- 企业公告 -->
            <div class="notice" id="qygg">
                <img class="img" src="../assets/notice.png" />
                <div class="notice-list">
                    <!-- 1 -->
                    <div class="detail">
                        <div class="left">
                            <div class="left-day">15</div>
                            <div class="left-year">2024.05</div>
                        </div>
                        <div class="right">
                            <div class="right-title">阳江蓝色农业现代海洋牧场示范基地项目(EPC)的招标邀请公告</div>
                            <div class="right-text">我司计划于2024年5月16日发布一项针对阳江蓝色农业现代海洋牧场示范基地项目（EPC）的招标邀请。招标邀请流程如下：</div>
                        </div>
                        <el-button class="button" type="text" @click="noticeDialog1()">查看详情</el-button>
                    </div>
                    <!-- 2 -->
                    <div class="detail">
                        <div class="left">
                            <div class="left-day">5</div>
                            <div class="left-year">2024.06</div>
                        </div>
                        <div class="right">
                            <div class="right-title">招标项目中标公告</div>
                            <div class="right-text">招标项目中标公告</div>
                        </div>
                        <el-button class="button" type="text" @click="noticeDialog2()">查看详情</el-button>
                    </div>
                    <!-- 3 -->
                    <div class="detail">
                        <div class="left">
                            <div class="left-day">15</div>
                            <div class="left-year">2024.06</div>
                        </div>
                        <div class="right">
                            <div class="right-title">阳江蓝色农业现代海洋牧场示范基地项目采购意向书</div>
                            <div class="right-text">阳江海心科技有限公司 阳江蓝色农业现代海洋牧场示范基地项目 采购意向</div>
                        </div>
                        <el-button class="button" type="text" @click="noticeDialog3()">查看详情</el-button>
                    </div>
                </div>
                <!-- 1 -->
                <el-dialog title="" :visible.sync="centerNoticeDialog1" width="70%" center align-center>
                    <el-scrollbar>
                        <div class="notice__dialog">
                            <span class="noticeMain">
                                <img class="noticeImg" src="../assets/notice1.png" />
                            </span>
                            <br /><br />
                            <div class="href" @click="frontDownload1">点击此处下载：阳江蓝色农业现代海洋牧场示范基地项目(EPC)的招标邀请公告.PDF</div>
                            <span slot="footer" class="dialog-footer">
                                <el-button @click="centerNoticeDialog1 = false">取 消</el-button>
                                <el-button type="primary" @click="centerNoticeDialog1 = false">确 定</el-button>
                            </span>
                        </div>
                    </el-scrollbar>
                </el-dialog>
                <!-- 2 -->
                <el-dialog title="" :visible.sync="centerNoticeDialog2" width="70%" center align-center>
                    <el-scrollbar>
                        <div class="notice__dialog">
                            <span class="noticeMain">
                                <img class="noticeImg" src="../assets/notice2.png" />
                            </span>
                            <br /><br />
                            <div class="href" @click="frontDownload2">点击此处下载：招标项目中标公告.PDF</div>
                            <span slot="footer" class="dialog-footer">
                                <el-button @click="centerNoticeDialog2 = false">取 消</el-button>
                                <el-button type="primary" @click="centerNoticeDialog2 = false">确 定</el-button>
                            </span>
                        </div>
                    </el-scrollbar>
                </el-dialog>
                <!-- 3 -->
                <el-dialog title="" :visible.sync="centerNoticeDialog3" width="70%" center align-center>
                    <el-scrollbar>
                        <div class="notice__dialog">
                            <span class="noticeMain">
                                <img class="noticeImg" src="../assets/notice3.png" />
                            </span>
                            <br /><br />
                            <!-- <div class="href" @click="frontDownload1">点击此处下载：阳江蓝色农业现代海洋牧场示范基地项目(EPC)的招标邀请公告.PDF</div> -->
                            <span slot="footer" class="dialog-footer">
                                <el-button @click="centerNoticeDialog3 = false">取 消</el-button>
                                <el-button type="primary" @click="centerNoticeDialog3 = false">确 定</el-button>
                            </span>
                        </div>
                    </el-scrollbar>
                </el-dialog>
            </div>
        </div>

        <!-- 联系我们 -->
        <div class="footer" id="lxwm">
            <div class="footer-top">
                <img class="footerImg" src="../assets/footer.png" />
                <div class="list">
                    <div class="list-title">业务布局</div>
                    <div class="list-text">现代化海洋牧场</div>
                    <div class="list-text">文旅结合区</div>
                    <div class="list-text">岸基配套产业园</div>
                    <div class="list-text">产品中心</div>
                </div>
                <div class="list">
                    <div class="list-title">关于我们</div>
                    <div class="list-text">公司介绍</div>
                    <div class="list-text">企业愿景</div>
                    <div class="list-text">企业使命</div>
                    <div class="list-text">核心价值观</div>
                </div>
                <div class="list">
                    <div class="list-title">实时资讯</div>
                    <div class="list-text">新闻资讯</div>
                    <div class="list-text">企业公告</div>
                </div>
                <div class="list">
                    <div class="list-title">联系我们</div>
                    <div class="list-text">电话： 020-89280082</div>
                    <div class="list-text">邮箱： project02@kasivest.com</div>
                </div>
            </div>
            <div class="footer-num">备案序号：<a href="https://beian.miit.gov.cn/">粤ICP备2024246352号-1</a></div>
        </div>
    </div>
</template>

<script>
import wlogo from '@/assets/wlogo.png'
import blogo from '@/assets/blogo.png'
import './swiper-bundle.min.css'

export default {
    data() {
        return {
            centerNewsDialog1: false,
            centerNewsDialog2: false,
            centerNoticeDialog1: false,
            centerNoticeDialog2: false,
            centerNoticeDialog3: false,
            wlogo,
            blogo,
            isVisable: false,
            isLogo: false,
            preview: [
                {
                    name: '1',
                    src: require('@/assets/preview1.png')
                },
                {
                    name: '2',
                    src: require('@/assets/preview2.png')
                }
            ],
            previewIndex: 0,
            imgList1: [
                {
                    name: '1',
                    src: require('@/assets/imgList1 (1).png')
                },
                {
                    name: '2',
                    src: require('@/assets/imgList1 (2).png')
                },
                {
                    name: '3',
                    src: require('@/assets/imgList1 (3).png')
                },
                {
                    name: '4',
                    src: require('@/assets/imgList1 (4).png')
                },
                {
                    name: '5',
                    src: require('@/assets/imgList1 (5).png')
                },
                {
                    name: '6',
                    src: require('@/assets/imgList1 (6).png')
                },
                {
                    name: '7',
                    src: require('@/assets/imgList1 (7).png')
                }
            ],
            imgList2: [
                {
                    name: '1',
                    src: require('@/assets/imgList2 (1).png')
                },
                {
                    name: '2',
                    src: require('@/assets/imgList2 (2).png')
                },
                {
                    name: '3',
                    src: require('@/assets/imgList2 (3).png')
                },
                {
                    name: '4',
                    src: require('@/assets/imgList2 (4).png')
                },
                {
                    name: '5',
                    src: require('@/assets/imgList2 (5).png')
                },
                {
                    name: '6',
                    src: require('@/assets/imgList2 (6).png')
                }
            ],
            imgList3: [
                {
                    name: '1',
                    src: require('@/assets/imgList3 (1).png')
                },
                {
                    name: '2',
                    src: require('@/assets/imgList3 (2).png')
                },
                {
                    name: '3',
                    src: require('@/assets/imgList3 (3).png')
                },
                {
                    name: '4',
                    src: require('@/assets/imgList3 (4).png')
                },
                {
                    name: '5',
                    src: require('@/assets/imgList3 (5).png')
                }
            ]
        }
    },
    created() {
        //在窗口滚动时调用监听窗口滚动方法
        this.$nextTick(() => {
            window.addEventListener('scroll', this.windowScrollListener, true)
        })
    },
    destroyed() {
        // 销毁监听不然耗性能
        window.removeEventListener('scroll', this.handleScroll)
    },
    mounted() {
        setTimeout(() => {
            new Swiper('.mySwiper', {
                loop: true,
                slidesPerView: 'auto',
                pagination: {
                    el: '.swiper-pagination',
                    type: 'fraction'
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            })
        }, 1000)
    },
    methods: {
        newsDialog1() {
            this.centerNewsDialog1 = true
        },
        newsDialog2() {
            this.centerNewsDialog2 = true
        },
        noticeDialog1() {
            this.centerNoticeDialog1 = true
        },
        noticeDialog2() {
            this.centerNoticeDialog2 = true
        },
        noticeDialog3() {
            this.centerNoticeDialog3 = true
        },
        frontDownload1() {
            var a = document.createElement('a') //创建一个<a></a>标签
            a.href = '/static/阳江蓝色农业现代海洋牧场示范基地项目(EPC)的招标邀请公告.pdf' // 给a标签的href属性值加上地址，注意，这里是绝对路径，不用加 点.
            a.download = '阳江蓝色农业现代海洋牧场示范基地项目(EPC)的招标邀请公告.pdf' //设置下载文件文件名，这里加上.xlsx指定文件类型，pdf文件就指定.fpd即可
            a.style.display = 'none' // 障眼法藏起来a标签
            document.body.appendChild(a) // 将a标签追加到文档对象中
            a.click() // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
            a.remove() // 一次性的，用完就删除a标签
        },
        frontDownload2() {
            var a = document.createElement('a') //创建一个<a></a>标签
            a.href = '/static/招标项目中标公告.pdf' // 给a标签的href属性值加上地址，注意，这里是绝对路径，不用加 点.
            a.download = '招标项目中标公告.pdf' //设置下载文件文件名，这里加上.xlsx指定文件类型，pdf文件就指定.fpd即可
            a.style.display = 'none' // 障眼法藏起来a标签
            document.body.appendChild(a) // 将a标签追加到文档对象中
            a.click() // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
            a.remove() // 一次性的，用完就删除a标签
        },

        windowScrollListener() {
            var scrollTop = Math.floor(document.documentElement.scrollTop)
            console.log(scrollTop, 'scrollTop')
            if (scrollTop >= 1) {
                this.isVisable = true //大于480时显示元素
                this.isLogo = true
            }
            if (scrollTop < 1) {
                this.isVisable = false //小于480时隐藏元素
                this.isLogo = false
            }
        },
        sy() {
            const element = document.getElementById('sy')
            element.scrollIntoView({
                block: 'start',
                inline: 'nearest',
                behavior: 'smooth'
            })
        },
        ywbj() {
            const element = document.getElementById('ywbj')
            element.scrollIntoView({
                block: 'start',
                inline: 'nearest',
                behavior: 'smooth'
            })
        },
        gywm() {
            const element = document.getElementById('gywm')
            element.scrollIntoView({
                block: 'start',
                inline: 'nearest',
                behavior: 'smooth'
            })
        },
        xwzx() {
            const element = document.getElementById('xwzx')
            element.scrollIntoView({
                block: 'start',
                inline: 'nearest',
                behavior: 'smooth'
            })
        },
        qygg() {
            const element = document.getElementById('qygg')
            element.scrollIntoView({
                block: 'start',
                inline: 'nearest',
                behavior: 'smooth'
            })
        },
        lxwm() {
            const element = document.getElementById('lxwm')
            element.scrollIntoView({
                block: 'start',
                inline: 'nearest',
                behavior: 'smooth'
            })
        }
    }
}
</script>

<style lang="scss">
.el-dialog__wrapper {
    display: flex;
    align-items: center;
}

.el-dialog {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    height: 80vh;
    display: flex;
    flex-direction: column;

    .el-dialog__body {
        flex-grow: 1;
        height: 0;
    }

    .dialog-footer {
        margin-top: 40px;
        display: flex;
        justify-content: center;
    }
}

.el-scrollbar {
    height: 100%;

    .el-scrollbar__wrap {
        overflow-x: hidden;
    }
}
</style>

<style lang="scss" scoped>
.swiper-list + .swiper-list {
    margin-top: 108px;
}

.swiper-list {
    position: relative;
    padding-left: calc(480px + 36px);
    margin-top: 215px;
    // height: 340px;
    display: flex;

    .head {
        position: absolute;
        left: 0;
        height: 100%;
    }

    .swiper-slide {
        // height: 340px;
    }

    .swiper-slide + .swiper-slide {
        margin-left: 20px;
    }

    .swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        width: auto !important;
        text-align: center;
        font-size: 18px;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .swiper-slide img {
        display: block;
        width: 480px !important;
        height: 340px !important;
        object-fit: cover;
    }

    .swiper {
        width: 100%;
        // height: 340px;
    }

    .swiper-button-next {
        right: 60px;
        width: 60px;
        height: 60px;
    }
}

.page {
    user-select: none;
    cursor: default;
    .header {
        display: flex;
        justify-content: space-between;
        z-index: 999;
        position: fixed;
        width: 100%;
        margin: 0;
        padding: 0;
        .logo {
            margin: 30px 0 0 120px;
            height: 80px;
        }
        .right {
            margin-right: 120px;
            display: flex;
            justify-content: space-between;
            color: #fff;
            font-weight: 500;
            font-size: 24px;
            line-height: 120px;
            .button {
                margin-left: 64px;
                cursor: pointer;
            }
        }
    }
    .header-active {
        background: #fff;
        box-shadow: 0px 0px 40px 0px rgba(102, 102, 102, 0.2);
        display: flex;
        justify-content: space-between;
        z-index: 999;
        position: fixed;
        width: 100%;
        margin: 0;
        padding: 0;
        .logo {
            margin: 30px 0 0 120px;
            height: 80px;
        }
        .right {
            margin-right: 120px;
            display: flex;
            justify-content: space-between;
            font-weight: 500;
            font-size: 24px;
            line-height: 120px;
            color: #333;
            .button {
                margin-left: 64px;
            }
        }
    }
    .img {
        width: 100%;
        height: 100%;
    }
    .img2 {
        margin-top: -10px;
        width: 100%;
        height: 100%;
    }
    .body {
        margin: 0 80px;
        .business {
            color: #333333;
            padding-top: 120px;
            &-img {
                height: 388px;
            }
            &-main {
                display: flex;
                .left {
                    height: 432px;
                }
                .right {
                    font-size: 40px;
                    line-height: 47px;
                    text-align: left;
                    .title {
                        padding: 24px 0;
                        border-bottom: 3px solid #dedede;
                        font-weight: bold;
                    }
                    .text {
                        padding: 24px 110px 24px 0;
                        font-size: 24px;
                        line-height: 60px;
                        text-align: left;
                    }
                }
            }
            .main {
                margin-bottom: 54px;
                padding: 52px 150px;
                background: #44bcff;
                font-size: 24px;
                color: #ffffff;
                line-height: 48px;
                text-align: left;
            }
        }

        .oyster-farming {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: center;
            box-shadow: 0px 2px 30px 15px rgba(47, 75, 55, 0.1);

            img {
                width: 100%;
            }

            .farming__button__group {
                position: absolute;
                bottom: 42px;
                display: flex;

                .group__item {
                    width: 57px;
                    height: 8px;
                    background: #cdcdcd;
                    border-radius: 4px;
                    cursor: pointer;
                }

                .group__item.active {
                    background: #44bcff;
                }

                .group__item + .group__item {
                    margin-left: 20px;
                }
            }
        }

        .fusion {
            margin: 240px 0 170px 0;
        }

        .industrial {
            margin-top: 214px;
        }

        .product {
            margin-top: 169px;
        }

        .fusion,
        .industrial,
        .product {
            display: flex;
            &-left {
                height: 260px;
            }
            &-right {
                margin-left: 50px;
                color: #333333;
                .title {
                    height: 84px;
                    border-bottom: 3px solid #dedede;
                    font-weight: bold;
                    font-size: 40px;
                    line-height: 84px;
                    text-align: left;
                }
                .main {
                    padding: 24px 110px 24px 0;
                    font-size: 24px;
                    line-height: 60px;
                    text-align: left;
                }
            }
        }
        .detail {
            display: flex;
            &-img {
                height: 721px;
            }
            &-main {
                padding: 110px 66px;
                font-size: 24px;
                color: #333333;
                line-height: 48px;
                text-align: left;
            }
        }

        .imgList {
            margin-top: 215px;
            display: flex;
            .head {
                margin-right: 36px;
                height: 340px;
            }
            .el-carousel {
                width: 1440px;
            }
        }

        .imgList + .imgList {
            margin-top: 108px;
        }

        .photo {
            margin-top: 67px;
            display: flex;
            justify-content: space-between;

            .img {
                width: auto;
                height: 144px;
                object-fit: cover;
            }

            img + img {
                margin-left: 35px;
            }
        }
        .about {
            padding-top: 120px;
            margin-top: 252px;
            display: flex;
            flex-direction: column;
            &-img {
                margin-bottom: 52px;
                width: 213px;
                height: 340px;
            }
            &-top {
                margin-bottom: 82px;
                display: flex;
                .left {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    .box1 {
                        padding-bottom: 24px;
                        background: #f6f7f8;
                        border-radius: 32px 0px 0px 0px;
                        text-align: center;
                        .num {
                            font-weight: bold;
                            font-size: 120px;
                            color: #44bcff;
                            line-height: 140px;
                        }
                        .unit {
                            font-weight: 500;
                            font-size: 36px;
                            color: #333333;
                            line-height: 42px;
                        }
                    }
                    .box2 {
                        padding-bottom: 24px;
                        margin-top: 40px;
                        background: #f6f7f8;
                        border-radius: 32px 0px 0px 0px;
                        text-align: center;
                        .num {
                            font-weight: bold;
                            font-size: 120px;
                            color: #44bcff;
                            line-height: 140px;
                            .yue {
                                font-weight: bold;
                                font-size: 36px;
                                color: #333;
                                line-height: 140px;
                            }
                        }
                        .unit {
                            font-weight: 500;
                            font-size: 36px;
                            color: #333333;
                            line-height: 42px;
                        }
                    }
                }
                .right {
                    padding-left: 40px;
                    &-img {
                        height: 450px;
                    }
                }
            }
            &-main {
                margin-bottom: 66px;
                display: flex;
                font-size: 24px;
                color: #333333;
                line-height: 48px;
                text-align: left;
                .box1 {
                    padding: 0 100px;
                    border-right: 1px solid #787878;
                }
                .box2 {
                    padding: 0 100px;
                }
            }
            .img {
                margin-top: 24px;
                height: 100%;
            }
        }
        .figure {
            margin: 0;
            margin-top: 56px;
            font-size: 0;
            &-column {
                position: relative;
                display: inline-block;
                width: 25%;
                height: 730px;
                cursor: pointer;
                overflow: hidden;
                &-item,
                &-active {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    // 切换速度
                    transition: 0.5s;
                }
                &-item {
                    top: 0;
                    .img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: -1;
                    }
                }
                &-active {
                    top: 360px;
                    opacity: 0;
                    .img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: -1;
                    }
                }
                &:hover {
                    .figure-column-item {
                        // 1图上移高度
                        top: -730px;
                        .icon {
                            opacity: 0;
                        }
                    }
                    .figure-column-active {
                        top: 0;
                        opacity: 1;
                    }
                }
            }
        }
        .bgImg {
            padding: 100px 212px 200px 212px;
            height: 740px;
        }
        .news {
            padding-top: 120px;
            .img {
                width: 460px;
                height: 360px;
            }
            .news__container {
                margin-top: 48px;
                padding-top: 56px;
                border-top: 3px solid #dedede;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                gap: 40px;

                .container__item {
                    display: flex;
                    flex-direction: column;
                    box-shadow: 0px 2px 30px 15px rgba(47, 75, 55, 0.1);

                    img {
                        height: 240px;
                        object-fit: cover;
                    }

                    .item__main {
                        padding: 0 38px;
                        color: #b6b6b6;
                        display: flex;
                        flex-direction: column;
                        .title {
                            width: 320px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            padding: 14px 0 18px 0;
                            font-weight: 500;
                            font-size: 24px;
                            color: #333;
                            line-height: 28px;
                            text-align: left;
                        }
                        .button {
                            padding-bottom: 24px;
                            display: flex;
                            justify-content: space-between;
                            &-day {
                                padding: 4px 0;
                                font-weight: 500;
                                font-size: 20px;
                                color: #a2a2a2;
                                line-height: 24px;
                                text-align: left;
                            }
                            &-detail {
                                padding: 4px 16px;
                                font-weight: 500;
                                font-size: 20px;
                                background: #44bcff;
                                color: #ffffff;
                                line-height: 24px;
                                text-align: center;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

            .news__dialog {
                .newsImg {
                    padding: 0 25%;
                    height: 50%;
                    width: 50%;
                }
                .href {
                    text-align: center;
                    a {
                        text-decoration: none;
                        color: #44bcff;
                    }
                }
                .newsMain {
                    font-weight: 500;
                    font-size: 16px;
                    color: #333333;
                    line-height: 32px;
                    text-align: left;
                    text-indent: 2em;
                }
            }
        }

        .notice {
            padding-top: 120px;
            .img {
                width: 580px;
                height: 360px;
            }
            &-list {
                display: flex;
                flex-direction: column;
                .detail {
                    // margin-bottom: 95px;
                    padding: 60px 80px;
                    background-color: #fff;
                    // border-radius: 0px 60px 0px 60px;
                    border-bottom: 3px solid #dedede;

                    .left {
                        display: flex;
                        flex-direction: column;
                        text-align: center;
                        padding-right: 40px;
                        border-right: 3px solid #dedede;
                        &-day {
                            font-weight: bold;
                            font-size: 80px;
                            color: #44bcff;
                            line-height: 94px;
                        }
                        &-year {
                            font-weight: 500;
                            font-size: 32px;
                            color: #a2a2a2;
                            line-height: 38px;
                        }
                    }
                    .right {
                        display: flex;
                        flex-direction: column;
                        padding-left: 80px;
                        &-title {
                            margin: 16px 0;
                            font-weight: 500;
                            font-size: 32px;
                            color: #333333;
                            line-height: 38px;
                            text-align: left;
                        }
                        &-text {
                            width: 1030px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            padding-right: 120px;
                            font-weight: 400;
                            font-size: 24px;
                            color: #787878;
                            line-height: 48px;
                            text-align: left;
                        }
                    }
                    .button {
                        border-radius: 60px;
                        margin: auto;
                        width: 200px;
                        height: 48px;
                        background-color: #44bcff;
                        padding: 0 18px;
                        font-weight: 400;
                        font-size: 24px;
                        color: #fff;
                        line-height: 48px;
                        text-align: center;
                    }
                    &:hover {
                        padding: 60px 80px;
                        background-color: #44bcff;
                        border-radius: 0px 60px 0px 60px;
                        border-bottom: 3px solid #dedede;
                        background: url('@/assets/news-hover.png');
                        background-size: 100% 100%;

                        .left {
                            display: flex;
                            flex-direction: column;
                            text-align: center;
                            padding-right: 40px;
                            border-right: 3px solid #fff;
                            &-day {
                                font-weight: bold;
                                font-size: 80px;
                                color: #fff;
                                line-height: 94px;
                            }
                            &-year {
                                font-weight: 500;
                                font-size: 32px;
                                color: #fff;
                                line-height: 38px;
                            }
                        }
                        .right {
                            margin: 0;
                            display: flex;
                            flex-direction: column;
                            padding-left: 80px;
                            &-title {
                                margin: 16px 0;
                                font-weight: 500;
                                font-size: 32px;
                                color: #fff;
                                line-height: 38px;
                                text-align: left;
                            }
                            &-text {
                                width: 1030px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                padding-right: 120px;
                                font-weight: 400;
                                font-size: 24px;
                                color: #fff;
                                line-height: 48px;
                                text-align: left;
                            }
                        }
                        .button {
                            border-radius: 60px;
                            margin: auto;
                            width: 200px;
                            height: 48px;
                            background-color: #fff;
                            padding: 0 18px;
                            font-weight: 400;
                            font-size: 24px;
                            color: #44bcff;
                            line-height: 48px;
                            text-align: center;
                        }
                    }
                }
            }
            .notice__dialog {
                .noticeImg {
                    height: 100%;
                    width: 100%;
                }
                .href {
                    text-align: center;
                    color: #44bcff;
                    cursor: pointer;
                }
                .noticeMain {
                    font-weight: 500;
                    font-size: 16px;
                    color: #333333;
                    line-height: 32px;
                    text-align: left;
                    text-indent: 2em;
                }
            }
        }
    }
    .footer {
        margin-top: 300px;
        height: 332px;
        background: #44bcff;
        &-top {
            display: flex;
            justify-content: space-around;
            padding-top: 60px;
            .footerImg {
                padding-top: 35px;
                height: 154px;
            }
            .list {
                color: #ffffff;
                &-title {
                    margin-bottom: 22px;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 28px;
                }
                &-text {
                    margin-bottom: 16px;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 23px;
                }
            }
        }
        &-num {
            padding-top: 20px;
            color: #ffffff;
            text-align: center;
            font-weight: 400;
            font-size: 16px;
            line-height: 23px;
            a {
                text-decoration: none;
                color: #fff;
            }
        }
    }
}
</style>
